/* eslint-disable eqeqeq */
import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/home/index.vue'

import Login from '../views/unlogin/login.vue'
import NotFound from '../views/error/notFound.vue'
import ActivityHome from '../views/activity/index.vue'
import StudentHome from '../views/mine/student.vue'
import StudyHome from '../views/study/index.vue'
import Training from '../views/study/training.vue'
import Course from '../views/study/course.vue'

import SimulationExercise from '../views/simulationExercise/index.vue'
import RandomTest from '../views/randomTest/index.vue'
import LearningData from '../views/learningData/index.vue'
import LearningDataDetail from '../views/learningData/detail.vue'
import Answer from '../views/doAnswer/index.vue'
import ApplyTeacher from '../views/teacher/apply.vue'
import Teacher from '../views/mine/teacher.vue'
import Admin from '../views/mine/admin.vue'
import StudentPaper from '../views/teacher/studentPaper.vue'
import StudentLearningDataDetail from '../views/teacher/studentLearningDataDetail.vue'
import AV from 'leancloud-storage'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/activity',
      name: 'Activity',
      component: ActivityHome
    },
    {
      path: '/student',
      name: 'Student',
      component: StudentHome
    },
    {
      path: '/study',
      name: 'Study',
      component: StudyHome
    },
    {
      path: '/training',
      name: 'Training',
      component: Training
    },
    {
      path: '/course',
      name: 'Course',
      component: Course
    },

    {
      path: '/notFound',
      name: 'NotFound',
      component: NotFound
    },

    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/simulationExercise',
      name: 'SimulationExercise',
      component: SimulationExercise
    },
    {
      path: '/randomTest',
      name: 'RandomTest',
      component: RandomTest
    },
    {
      path: '/learningData',
      name: 'LearningData',
      component: LearningData
    },
    {
      path: '/learningDataDetail',
      name: 'LearningDataDetail',
      component: LearningDataDetail
    },
    {
      path: '/answer',
      name: 'Answer',
      component: Answer
    },
    {
      path: '/applyTeacher',
      name: 'ApplyTeacher',
      component: ApplyTeacher
    },
    {
      path: '/teacher',
      name: 'Teacher',
      component: Teacher
    },
    {
      path: '/admin',
      name: 'Admin',
      component: Admin
    },
    {
      path: '/studentPaper',
      name: 'StudentPaper',
      component: StudentPaper
    },
    {
      path: '/studentLearningDataDetail',
      name: 'StudentLearningDataDetail',
      component: StudentLearningDataDetail
    }

  ]
})
router.beforeEach((to, from, next) => {
  if (!to.matched.length) {
    next({ name: 'NotFound' })
  } else {
    // console.log(to.name, AV.User.current())
    if (!AV.User.current() && to.name != 'Login') {
      if (to.name === 'ApplyTeacher') {
        next()
      } else {
        next({ name: 'Login' })
      }
    } else next()
  }
})
export default router
