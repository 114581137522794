
<template>
  <footer class="bg-white">
        <hr class="my-6 border-gray-100 w-full" />
    <div class="max-w-7xl mx-auto py-4 px-4 overflow-hidden">
      <!-- <nav class="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
        <div v-for="item in navigation.main" :key="item.name" class="px-5 py-2">
          <a :href="item.href" class="text-base text-gray-500 hover:text-gray-900">
            {{ item.name }}
          </a>
        </div>
      </nav> -->

      <p class="mt-8 text-center text-base text-gray-400 cursor-pointer">
        &copy; 江苏凤凰美术出版社  《2023年江苏省初中英语听力口语自动化考试纲要·解读与仿真训练》
      </p>
       <p class="mt-2 text-center text-base text-gray-400 flex flex-row justify-center">
        &copy;  <a target="_blank" href="https://www.hlingsoft.com" class="text-gray-400  hover:text-gyjdGreen-500 underline ">上海恒领软件有限公司提供技术支持</a>
        <!-- <a target="_blank" href="https://qm.qq.com/cgi-bin/qm/qr?k=H5uzfRB8uwWooHshTJXTYn16PwiB_v59&jump_from=webapi" class="ml-10 flex flex-row text-gray-400 hover:text-gyjdGreen-500"><span></span><img border="0" src="//pub.idqqimg.com/wpa/images/group.png" alt="江苏省初中英语听力口语自动化考试纲要·解读与仿真训练" title="江苏省初中英语听力口语自动化考试纲要·解读与仿真训练"></a> -->
      </p>
      <p class="mt-2 text-center text-base text-gray-400 hover:text-gyjdGreen-500 cursor-pointer">
        沪ICP备14034145号-5
      </p>
    </div>
  </footer>
</template>

<script>
import { defineComponent } from 'vue'

const navigation = {
  main: [
    { name: '关于我们', href: '#' },
    { name: '联系方式', href: '#' },
    { name: '友情链接', href: '#' }
  ]
}

export default defineComponent({
  name: 'global-footer',
  setup () {
    return {
      navigation
    }
  }
})
</script>
